const addToBcl = (name, component) => (bcl.s = { ...bcl.s, [name]: component });

const currency = {
  props: {
    currency: {
      market: "",
      current: "",
      rate: 1,
      useComma: false,
      useThousandsComma: false,
    },
    selectors: {
      //currencypicker: "#currency-select",
      priceItem: ".c-price__value-JS",
      useComma: "mod--currency-decimal-comma",
      useThousandsComma: "mod--currency-thousands-comma",
      mainCurrenciesItems: "currency-item-js",
      mainCurrenciesItemsContainer: "currecy-items-container-js",
      currencyTitleJS: "currency-title-JS",
      blockedCurrencyButton: ".blocked-currency-button-JS",
      blockedCurrencyTab: ".tab-blocked-currency-JS",
      priceContainer: ".c-price-JS",
      priceUnavailable: ".c-price-unavailable-JS",
      priceFrom: ".c-price-from-JS",
      parentPriceContainer: ".c-price-container-JS",
      currencyFilter: "#currency-filter",
    },
    attributes: {
      localStorageCurrency: "barcelo_currency",
      localStorageCurrencyRate: "barcelo_currency_rate",
      defaultCurrency: "data-currency",
      localStorageCurrencyRelativeRate: "barcelo_currency_relative_rate",
    },
    picker: null,
    marketPriceClone: false,
    mainCurrenciesContainer: false,
    allMainCurrencies: false,
    relativeRatesMap: null,
    marketRelativeRatesMap: null,
    isRelativePriceRequest: false,
    shouldBeRelativePrice: false,
    form: null,
    allCurrencies: null,
    allCurrencyLinks: null,
    currencyLinkSelector: ".c-locale-form__currency",
    allCurrenciesSelector: ".c-locale-form__currencies-all-JS",
    distributorCard: ".distributor-card-JS",
    cardItem: ".card-hotel-price-JS",
    mybPriceItem: ".c-mybprice__value-JS",
    mybPriceItemContainer: ".c-price-myb__container",
    priceItem: ".c-price__value-JS",
  },

  init: function () {
    const body = document.querySelector("BODY");

    this.decrytPrices(body);

    if (bcl.editMode) {
      return;
    }

    bcl.u.utag.enableListener();

    if (this.props.picker) {
      const { picker } = this.props;
      picker.addEventListener("change", () => {
        const selectedOption = picker.options[picker.selectedIndex];
        this.changeCurrency(picker.value, selectedOption.dataset.rate, selectedOption.title, null, selectedOption.dataset.relativerate);
      });

      const currencyTitleItems = document.getElementsByClassName(this.props.selectors.currencyTitleJS);
      for (const item of currencyTitleItems) {
        item.innerHTML = this.props.picker.options[this.props.picker.selectedIndex].title;
      }
    }

    this.props.currency.useComma = bcl.u.containsClass(body, this.props.selectors.useComma);
    this.props.currency.useThousandsComma = bcl.u.containsClass(body, this.props.selectors.useThousandsComma);

    this.props.currency.market = body.getAttribute(this.props.attributes.defaultCurrency);
    this.props.marketRelativeRatesMap = body.dataset.marketrelativeratesmap ? JSON.parse(body.dataset.marketrelativeratesmap)?.ratesMap : null;

    let customCurrencySet = localStorage.getItem(this.props.attributes.localStorageCurrency);
    const customRate = localStorage.getItem(this.props.attributes.localStorageCurrencyRate);

    if (customCurrencySet === this.props.currency.market) {
      localStorage.removeItem(this.props.attributes.localStorageCurrency);
      customCurrencySet = null;
    }

    this.props.currency.current = this.props.currency.market;

    if (customCurrencySet) {
      if (this.props.picker) {
        this.props.picker.value = customCurrencySet;
        const selectedOption = this.props.picker.options[this.props.picker.selectedIndex];
        if (selectedOption) {
          this.changeCurrency(customCurrencySet, selectedOption.dataset.rate, selectedOption.title, true);
        }
      } else if (customRate) {
        this.changeCurrency(customCurrencySet, customRate, null, true);
      }
    } else {
      this.changeCurrentCurrencyMegamenu(this.props.currency.market);
    }

    setTimeout(() => this.blockedCurrencyChecker(), 1000);

    if (bcl.u.utag.isEnabled() && utag_data.store_currency) {
      const value = this.props.currency.current?.toLowerCase();
      bcl.u.utag.add({ user_selected_currency: value });
    }

    bcl.u.utag.removeListener();
    addToBcl("currency", this);

    if (!this.props.picker) {
      const form = document.querySelector(".locale-form-JS");
      if (form?.dataset.href) {
        bcl.ajax.getRequest(null, form.dataset.href, this.obtainTitleCurrency.bind(this));
      }
    }

    this.initCurrencyFilter();

    const buttonCurrencyDropdown = document.querySelector(".currency-dropdown-JS");
    if (buttonCurrencyDropdown) {
      buttonCurrencyDropdown.addEventListener("click", () => this.watchCurrencyVisibility());
    }

    const dropdown = document.querySelector(".languageCurrencySelector .c-popover.container-contents-JS");
    if (dropdown) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "attributes" && mutation.attributeName === "class" && mutation.target.classList.contains("mod--active")) {
            const currencyForm = document.querySelector(".currencyForm-JS");
            currencyForm?.parentElement.scrollBy(0, -3000);
            observer.disconnect();
          }
        }
      });

      observer.observe(dropdown, { attributes: true });
    }
  },

  obtainTitleCurrency: function (response) {
    const currencyCode = localStorage.getItem(this.props.attributes.localStorageCurrency) || document.querySelector("BODY").dataset.currency;
    let titleCurrentCurrency;

    const div = document.createElement("div");
    div.innerHTML = response.responseText;
    this.props.form = div.querySelector(".c-locale-form-JS");

    if (this.props.form) {
      this.props.allCurrencies = this.props.form.querySelector(this.props.allCurrenciesSelector);

      if (this.props.allCurrencies) {
        const currencyItems = this.props.allCurrencies.querySelectorAll(this.props.currencyLinkSelector);

        for (const item of currencyItems) {
          if (currencyCode === item.dataset.name) {
            titleCurrentCurrency = item.title;
            break;
          }
        }
      }
    }

    const currencyTitleElements = document.getElementsByClassName(this.props.selectors.currencyTitleJS);
    for (const element of currencyTitleElements) {
      element.innerHTML = titleCurrentCurrency;
    }
  },

  blockedCurrencyChecker: function () {
    const blockedCurrencyButton = document.querySelector(this.props.selectors.blockedCurrencyButton);

    if (blockedCurrencyButton) {
      const blockedCurrency = blockedCurrencyButton.dataset.value;
      if (blockedCurrency) {
        this.changeCurrency(blockedCurrency, this.props.currency.rate, null, true);
      }
    }
  },

  decrytPrices: function (dom) {
    const mapping = {
      ",": "A",
      ".": "B",
      0: "C",
      1: "D",
      2: "E",
      3: "F",
      4: "G",
      5: "H",
      6: "I",
      7: "J",
      8: "K",
      9: "L",
    };

    const decrypt = (price) => {
      if (typeof price === "string") {
        for (const [char, code] of Object.entries(mapping)) {
          const re = new RegExp(code, "g");
          price = price.replace(re, char);
        }
      }
      return price;
    };

    const container = dom || document;
    const items = container.querySelectorAll(this.props.selectors.priceItem);

    items.forEach((elem) => {
      elem.innerText = decrypt(elem.innerText);
      bcl.u.removeClass(elem, "hidden");
    });
  },

  parseTextPrice: function (text) {
    if (!text) {
      return 0;
    }

    const floatPrice = parseFloat(text.replace(/\D/g, ""));

    if (!isNaN(floatPrice)) {
      return floatPrice;
    } else {
      console.warn("Error on parsing price", text);
      return 0;
    }
  },

  formatPrice: function (floatValue) {
    if (isNaN(floatValue)) {
      console.warn("Invalid number for formatting:", floatValue);
      return floatValue;
    }

    const separator = this.props.currency.useThousandsComma ? "," : ".";
    const regex = new RegExp(`(\\d)(?=(\\d{3})+(?!\\d))`, "g");

    return floatValue
      .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
      .replace(/,/g, "X") // Temporarily replace the default thousand separator
      .replace(regex, `$1${separator}`) // Replace with the chosen separator
      .replace(/X/g, ","); // Restore the default thousand separator
  },

  changeCurrency: function (newCurrency, rate, title, init, relativeRate) {
    if (!init && bcl.u.utag.isEnabled() && utag_data.store_currency) {
      this.convertUtagPrices(newCurrency, rate);
    }

    this.props.currency.rate = rate;
    this.props.currency.relativeRate = relativeRate || this.props.currency.relativeRate || rate;

    if (newCurrency) {
      localStorage.setItem(this.props.attributes.localStorageCurrency, newCurrency);
    }
    if (rate) {
      localStorage.setItem(this.props.attributes.localStorageCurrencyRate, rate);
    }
    if (relativeRate) {
      localStorage.setItem(this.props.attributes.localStorageCurrencyRelativeRate, relativeRate);
    }

    const body = document.querySelector("BODY");
    bcl.u.removeClass(body, `mod--${this.props.currency.current}`);
    bcl.u.addClass(body, `mod--${newCurrency}`);

    this.props.currency.current = newCurrency;

    this.props.shouldBeRelativePrice = this.props.isRelativePriceRequest && bcl.c.hotelRoomList;
    this.convertPrices();
    this.changeCurrentCurrencyMegamenu(newCurrency);

    if (bcl.c.distributor) {
      const { results, comparator, map } = bcl.c.distributor;
      const { $container, classNameContainerCard, classNameContainerCardHotelSurprise, nameAttrCard } = results.props;

      if ($container) {
        const cards = $container.querySelectorAll(`.${classNameContainerCard}, .${classNameContainerCardHotelSurprise}`);
        cards.forEach((card) => {
          const path = card.getAttribute(nameAttrCard);
          [comparator.props.markers, map.props.markers].forEach((markerMap) => {
            const marker = markerMap[path];
            if (marker) {
              this.updateLabelPrice(marker);
            }
          });
        });
      }

      comparator.updateMap();
    }

    if (title) {
      document.querySelectorAll(`.${this.props.selectors.currencyTitleJS}`).forEach((item) => {
        item.innerHTML = title;
      });
    }

    bcl.s.tabs.closeActive(this.props.isRelativePriceRequest);
  },
  updateLabelPrice: function (marker) {
    if (marker) {
      bcl.c.distributor.map.updateLabelMarker("currency", marker);
    }
  },

  changeCurrentCurrencyMegamenu: function (newCurrency) {
    const span = document.querySelector(".currency-current-JS");

    if (span) {
      span.textContent = newCurrency;
    }
  },

  convertUtagPrices: function (newCurrency, rate) {
    const userCurrency = newCurrency.toLowerCase();
    const storeCurrency = utag_data.store_currency.toLowerCase();

    utag_data.user_selected_currency = userCurrency;

    if (userCurrency === storeCurrency) {
      utag_data.booking_price_exchange = utag_data.booking_price;
    } else if (utag_data.booking_price) {
      utag_data.booking_price_exchange = this.setPriceRounded(rate, utag_data.booking_price);
    }

    bcl.u.utag.push({
      store_currency: storeCurrency,
      user_selected_currency: userCurrency,
      booking_price: utag_data.booking_price,
      booking_price_exchange: utag_data.booking_price_exchange,
    });
  },

  setPriceRounded: function (rate, utagBookingPrice) {
    const priceConverted = parseFloat(utagBookingPrice) * rate;
    const priceFloor = Math.ceil(priceConverted);

    return (priceConverted > priceFloor ? priceFloor + 1 : priceFloor).toString();
  },

  setRelativeRatesInOptions: function (ratesMap) {
    const { $suggestedCurrencies = [], $allCurrencyLinks = [] } = bcl.c.localeConfigurationForm.props;
    const allCurrencies = [...$suggestedCurrencies, ...$allCurrencyLinks];

    allCurrencies.forEach(($option) => {
      const $labelParent = bcl.u.closest($option, "label");
      const $currencyName = $labelParent?.querySelector("input");
      if ($currencyName) {
        $option.dataset.relativerate = ratesMap[$currencyName.value]?.rate || "";
      }
    });
  },

  convertPrices: function (dom) {
    const container = dom || document;
    const mybPriceSelector = bcl.c.hotelRoomList ? `, ${bcl.c.hotelRoomList.props.mybPriceSelector}` : "";
    const items = container.querySelectorAll(`${this.props.selectors.priceItem}${mybPriceSelector}`);

    items.forEach((elem) => {
      if (!elem.dataset.marketPrice || !/^\d+$/.test(elem.dataset.marketPrice)) {
        elem.dataset.marketPrice = this.parseTextPrice(elem.innerText);
      }

      if (elem.dataset.marketPrice) {
        const marketPrice = parseFloat(elem.dataset.marketPrice);
        elem.dataset.relativeprice = marketPrice;

        if (this.props.currency.current !== document.body.dataset.currency && this.props.marketRelativeRatesMap) {
          const rate = this.props.marketRelativeRatesMap[this.props.currency.current]?.rate || 1;
          elem.dataset.relativeprice = Math.ceil(marketPrice * rate);
        }

        elem.innerText = this.formatPrice(elem.dataset.relativeprice);
      }

      this.toggleUnavailablePrice(elem);
    });

    this.props.marketPriceClone = true;
  },

  toggleUnavailablePrice: function (elem) {
    if (elem.dataset.marketPrice > 0) {
      const priceContainer = bcl.u.closest(elem, this.props.selectors.priceContainer);

      if (priceContainer) {
        bcl.u.removeClass(priceContainer, "hidden");

        const priceUnavailable = priceContainer.parentNode.querySelector(this.props.selectors.priceUnavailable);
        if (priceUnavailable) {
          bcl.u.addClass(priceUnavailable, "hidden");
        }

        const parentPriceContainer = bcl.u.closest(elem, this.props.selectors.parentPriceContainer);
        const priceFromLabel = parentPriceContainer?.querySelector(this.props.selectors.priceFrom);
        if (priceFromLabel) {
          bcl.u.removeClass(priceFromLabel, "hidden");
        }
      }
    }
  },

  convertAndFormatPrice: function (floatPrice) {
    const convertedPrice = Math.ceil(floatPrice * this.props.currency.rate);
    return this.formatPrice(convertedPrice);
  },

  convertToMarketPrice: function (floatPrice, isRelativePriceRequest) {
    const marketRate = isRelativePriceRequest && this.props.relativeRatesMap ? this.props.relativeRatesMap[this.props.currency.market]?.rate : this.props.currency.rate;

    return this.formatPrice(Math.ceil(floatPrice * marketRate));
  },

  evaluateRelativeMarketRate: function (data) {
    const { relativeRates } = data;

    this.props.isRelativePriceRequest = Boolean(relativeRates);

    if (relativeRates) {
      const { ratesMap, base_currency } = relativeRates;
      const { current } = this.props.currency;

      this.props.relativeRatesMap = ratesMap;
      this.props.currency.relativeMarket = base_currency;
      this.props.currency.relativeRate = ratesMap[current]?.rate || null;
    }
  },

  initMainCurrencies: function () {
    const container = document.querySelector(`.${this.props.selectors.mainCurrenciesItemsContainer}`);

    if (!container) {
      return;
    }

    this.props.mainCurrenciesContainer = container;
    this.props.allMainCurrencies = Array.from(container.children);

    this.props.allMainCurrencies.forEach((mainCurrency) => {
      mainCurrency.addEventListener(
        "click",
        (e) => {
          e.preventDefault();

          const { value } = mainCurrency.dataset;
          const optionToClick = this.props.picker.querySelector(`[value="${value}"]`);
          const optionSelected = this.props.picker.querySelector('[selected="selected"]');

          this.removeAllActiveCurrencies();
          mainCurrency.classList.add("mod--active");

          if (optionToClick) {
            this.props.picker.value = value;
            if (optionSelected) {
              optionSelected.removeAttribute("selected");
            }
            optionToClick.setAttribute("selected", "selected");
            bcl.u.triggerEvent(optionToClick, "change");

            const { rate, relativerate } = optionToClick.dataset;
            this.changeCurrency(value, rate, null, null, relativerate);
          }
        },
        true,
      );
    });
  },

  removeAllActiveCurrencies: function () {
    this.props.allMainCurrencies.forEach((currency) => {
      bcl.u.removeClass(currency, "mod--active");
    });
  },

  changeMainCurrency: function () {
    if (!this.props.mainCurrenciesContainer || !this.props.picker) {
      return;
    }

    const selectedValue = this.props.picker.value;
    const mainCurrencyToSelect = this.props.mainCurrenciesContainer.querySelector(`[data-value="${selectedValue}"]`);

    this.removeAllActiveCurrencies();

    if (mainCurrencyToSelect) {
      bcl.u.addClass(mainCurrencyToSelect, "mod--active");
    }
  },

  initCurrencyFilter: function () {
    const currencyFilter = document.querySelector(this.props.selectors.currencyFilter);

    if (!currencyFilter) {
      return;
    }

    currencyFilter.addEventListener("input", (event) => {
      this.filterCurrencyOptions(event.target);
    });

    const currencySelectContainer = currencyFilter.closest(".currency-select-JS");
    const currencyOptions = currencySelectContainer ? currencySelectContainer.querySelectorAll("label") : [];

    currencyOptions.forEach((option) => {
      const optionInput = option.querySelector('input[name="currency"]');
      const isChecked = optionInput.value === bcl.s.currency.props.currency.current;

      optionInput.checked = isChecked;
      if (isChecked) {
        bcl.u.addClass(option.parentElement, "checked");
      }

      optionInput.addEventListener("change", (event) => {
        currencyOptions.forEach((opt) => bcl.u.removeClass(opt, "checked"));

        this.changeCurrency(event.target.value, event.target.dataset.rate, null, null);
        bcl.u.addClass(event.target.parentElement, "checked");
      });
    });

    const currencyText = document.querySelector(".currency_JS");
    const currentCurrency = document.querySelector(".currency_current");

    if (currencyText) {
      bcl.u.addClass(currencyText, "md:hidden");
    }

    if (currentCurrency) {
      bcl.u.removeClass(currentCurrency, "md:hidden");
    }
  },

  filterCurrencyOptions: function (input) {
    const text = input.value.toLowerCase();
    const currencyOptions = input.closest(".currency-select-JS")?.querySelectorAll("label") || [];

    this.hideCurrencyOptions(currencyOptions);
    this.showMatchedCurrency(input, text);
  },

  hideCurrencyOptions: function (currencyOptions) {
    if (!currencyOptions) {
      return;
    }

    currencyOptions.forEach((option) => bcl.u.addClass(option, "hidden"));
  },

  showMatchedCurrency: function (input, text) {
    if (!input) {
      return;
    }

    const query = text ? `input[data-name*='${text}' i], input[title*='${text}' i]` : "input";

    const filteredOptions = input.closest(".currency-select-JS")?.querySelectorAll(query) || [];

    filteredOptions.forEach((option) => {
      const label = option.closest("label");
      if (label) {
        bcl.u.removeClass(label, "hidden");
      }
    });

    this.sortMatchedCurrency(input);
  },

  sortMatchedCurrency: function (input) {
    if (!input) {
      return;
    }

    const optionContainer = input.nextElementSibling;
    if (!optionContainer) {
      return;
    }

    const sortedOptions = Array.from(optionContainer.querySelectorAll("label")).sort((a, b) => {
      const aInput = a.querySelector("input");
      const bInput = b.querySelector("input");

      if (aInput.checked && !bInput.checked) {
        return -1;
      }
      if (!aInput.checked && bInput.checked) {
        return 1;
      }

      const aHidden = a.parentElement.classList.contains("hidden");
      const bHidden = b.parentElement.classList.contains("hidden");

      if (aHidden && !bHidden) {
        return 1;
      }
      if (!aHidden && bHidden) {
        return -1;
      }

      const aSuggested = a.parentElement.classList.contains("currency-select-suggested");
      const bSuggested = b.parentElement.classList.contains("currency-select-suggested");

      if (aSuggested && !bSuggested) {
        return -1;
      }
      if (!aSuggested && bSuggested) {
        return 1;
      }

      return 0;
    });

    optionContainer.innerHTML = "";
    sortedOptions.forEach((option) => optionContainer.appendChild(option));
  },
};

export default currency;
