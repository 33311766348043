bcl.s.loadForm = {
  props: {
    loadJS: false,
    loadCSS: false,
  },

  init: function (baseDom) {
    if (bcl.s.loadForm.props.loadJS) {
      return;
    }

    bcl.s.loadForm.props.loadJS = true;

    const baseFind = baseDom ? baseDom : document,
      elements = baseFind.getElementsByClassName("form-JS");

    if (elements && elements.length) {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/others/clientlib-forms.css");
      bcl.u.loadScript({
        src: "/etc.clientlibs/barcelo/clientlibs/others/clientlib-forms.js",
        callback: bcl.s.loadForm.loadJS,
        type: "text/javascript",
        defer: true,
      });
    }
  },

  loadJS: function () {
    bcl.u.preInit();

    const editProfileV2 = document.querySelector(".js-myb-edit-profile-v2");

    if (editProfileV2) {
      bcl.c.mybEditProfileV2.init();
    } else {
      bcl.c.mybEditProfile.init();
    }
  },
};

const containerToObserve = document;

// Options for the observer
const options = {
  childList: true,
  subtree: true,
};

// Check and watch images
const callback = function (mutationList) {
  mutationList.forEach((mutation) => {
    mutation.addedNodes.forEach(function (node) {
      if (node.nodeType === Node.ELEMENT_NODE && document.querySelector(".form-JS")) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        observer.disconnect();
        bcl.s.loadForm.init();
      }
    });
  });
};

const observer = new MutationObserver(callback);
observer.observe(containerToObserve, options);
